import React from "react";
import Helmet from "react-helmet";

import "../assets/scss/main.scss";
import "../assets/scss/appstore.scss";

import logo from '../assets/images/mawanguide/logo.png'


class AppPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          loading: 'is-loading'
        }
      }
  
      componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 100);
      }
  
      componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
          
    render() {
        const { children } = this.props;
        const siteTitle = "馬灣通";

        return (
        <div className={`body ${this.state.loading}`}>

            <Helmet title={siteTitle} />

            <section id="footer">
                <div className="inner">
                    <span><img src={logo} alt="" width="120px" height="120px"/></span>
                    <h1><strong>馬灣通</strong></h1>
                    <p>馬灣/珀麗灣常用電話, 有齊衣,食,住,行的必用電話和班期表. 一按即通,方便易用. 是馬灣/珀麗灣居民必備App.</p>
                </div>

                <p></p>

                <ul className="icons">
                    <li>
                        <a className="icon appStoreButton" href='https://play.google.com/store/apps/details?id=com.sumlook.mawanguide'><img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="icon appStoreButton" href='https://apps.apple.com/us/app/mawanguide/id1544066440'><img alt='Get it on Apple App Store' src="/images/apple-app-store.png"/></a>
                        <a className="icon appStoreButton" href='https://appgallery.cloud.huawei.com/ag/n/app/C103002561?channelId=sumlook.com&referrer=sumlook.com&id=f1556856888c4c3a950a0a05017910e9&s=353853BFC368B43AF009F5119E973F42A306B759C5A4026A3F3E379EEBA7EA45&detailType=0&v='><img alt='Get it on Huawei AppGallery' src="/images/huawei-appgallery.png"/></a>
                    </li> 
                </ul>

                <ul className="copyright">
                    <li>© Sumlook Production</li>
                </ul>

            </section>


        </div>
        );
    }
}

export default AppPage;